import $ from "jquery";

document.addEventListener("DOMContentLoaded", function () {
  (function () {
    $('[data-js-target="consultation-table"]').ready(function () {
      $('[data-js-target="launch-calendly-modal"]').on("click", function () {
        $('[data-js-target="consultation-table"]').showSpinner();
        var calendlyURL = $(this).data("calendly-url");
        Calendly.showPopupWidget(calendlyURL, function () {
          location.reload();
        });
        return false;
      });
    });
  })();
});
