import $ from "jquery";

(function() {
  $(document).on('click', "[data-behavior-ios-fallback-link]", function(event) {
    var fallbackLink = $(event.target);
    window.setTimeout(function() {
      window.location = fallbackLink.data('fallback-url');
    }, 1);
    window.location = fallbackLink.data('url');
  });
}());
