import $ from "jquery";

document.addEventListener("DOMContentLoaded", function () {
  $.rails.disableFormElements = function (form) {
    var formIsInReactContent = $(form).parents(
      "[data-controller='react-app'],[data-controller='react-portal'],reach-portal"
    )[0];
    if (formIsInReactContent) {
      return;
    }

    $.rails.formElements(form[0], $.rails.formDisableSelector);

    if (!form.data("disable-no-spinner")) {
      const target = form?.data("disable-target") || form;
      $(target).showSpinner();
    }
  };

  $.rails.enableFormElements = function (form, indexed = true) {
    const formElement = indexed ? form[0] : form;

    $.rails.formElements(formElement, $.rails.formEnableSelector);

    if (!form.data("disable-permanently")) {
      var target = form.data("disable-target") || form;
      $(target).hideSpinner();
    }
  };

  // Modified version of https://github.com/rails/jquery-ujs/blob/master/src/rails.js??#L274 to allow use of 'disable-permanently'
  $.rails.enableFormElement = function (element) {
    if (!element.data("disable-permanently")) {
      var method = element.is("button") ? "html" : "val";
      if (element.data("ujs:enable-with") !== undefined) {
        element[method](element.data("ujs:enable-with"));
        element.removeData("ujs:enable-with"); // clean up cache
      }
      element.prop("disabled", false);
      element.removeData("ujs:disabled");
    }
  };

  // Mostly taken from here https://github.com/rails/jquery-ujs/blob/2830202d52dc073c8b6c2337755ad4ffbcbe8bf2/src/rails.js
  $.rails.disableElement = function (element) {
    var replacement = element.data("disable-with");
    var target = element.data("disable-target");
    if (target) {
      $(target).showSpinner();
    }

    element.data("ujs:enable-with", element.html()); // store enabled state
    if (replacement !== undefined) {
      element.html(replacement);
    }

    element.bind("click.railsDisable", function (e) {
      // prevent further clicking
      return $.rails.stopEverything(e);
    });
  };

  // Restore element to its original state which was disabled by 'disableElement' above
  $.rails.enableElement = function (element) {
    if (!element.data("disable-permanently")) {
      var target = element.data("disable-target");
      if (target) {
        $(target).hideSpinner();
      }

      if (element.data("ujs:enable-with") !== undefined) {
        element.html(element.data("ujs:enable-with")); // set to old enabled state
        element.removeData("ujs:enable-with"); // clean up cache
      }
      element.unbind("click.railsDisable"); // enable element
    }
  };

  document.addEventListener("page:restore", function () {
    $(document).hideSpinner();
  });

  // Workaround for issue where rails-ujs prematurely enables disabled elements for XHR requests with redirects.
  // This has been addressed in TurboLinks and Rails but not released as of March 2019.
  // See https://github.com/turbolinks/turbolinks-rails/pull/28, https://github.com/rails/rails/pull/31441, and
  // https://stackoverflow.com/questions/46831525/how-to-keep-submit-buttons-disabled-on-remote-forms-until-the-next-page-has-load/46844912
  $(document).on("ajax:send", "form[data-remote=true]", () => {
    const $form = $("form");

    $form.on("ajax:complete", (event) => {
      const [xhr, _status] = event.detail;

      if (xhr?.responseText?.includes("Turbolinks.visit")) {
        // Use setTimeout to prevent race-condition when Rails re-enables the button
        setTimeout(function () {
          $.rails.disableFormElements($form);
        }, 0);
      }
    });

    //Prevent button from being cached in disabled state
    $(document).one("turbolinks:before-cache", () => {
      $.rails.enableFormElements($form, false);
    });
  });

  document.addEventListener("visibilitychange", () => {
    const $form = $("form");

    if (document.visibilityState == "hidden" && $form[0]) {
      $.rails.enableFormElements($form);
    }
  });
});
