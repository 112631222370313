import $ from "jquery";

document.addEventListener("DOMContentLoaded", function () {
  (function () {
    $.fn.startFormTransition = function () {
      this.showSpinner();
      $.rails.disableFormElements(this);
    };

    $.fn.handleFormSubmissionError = function () {
      this.hideSpinner();
      $.rails.enableFormElements(this);
    };
  })();
});
