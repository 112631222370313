import _ from "lodash";
import $ from "jquery";

(function () {
  $.fn.balancesGraph = function (action, options) {
    if (action === "create") {
      var graph = new BalancesGraph(_.extend(options, { $el: this }));
      this.data("graph", graph);
      graph.create();

      return this;
    }

    if (action === "update") {
      var graph = this.data("graph");
      graph.update(options);

      return this;
    }

    if (action === "waiting") {
      var graph = this.data("graph");
      graph.waiting();
    }
  };

  function BalancesGraph(options) {
    var DEFAULTS = {
      margin: {
        top: 10,
        right: 1,
        left: Math.max(50, options.$el.width() * 0.08),
        bottom: 30,
      },
      graphHeight: 375,
      tooltipClass: ".PerformanceBalanceGraphTooltip",
      verticalBuffer: 1.15,
      transitionDuration: 800,
      transitionEasing: "elastic",
    };

    this.settings = $.extend(DEFAULTS, options);
    this.settings.$parentEl = this.settings.$el.parent();
    this.settings.width =
      this.settings.$el.width() -
      this.settings.margin.left -
      this.settings.margin.right;
    this.settings.height =
      Math.min(0.75 * this.settings.width, this.settings.graphHeight) -
      this.settings.margin.top -
      this.settings.margin.bottom;
    this.settings.xTicks = 2;
    this.settings.yTicks = 5;
  }

  _.extend(BalancesGraph.prototype, {
    hasData: function () {
      return this.settings.balanceByDate.length > 0;
    },

    create: function () {
      this.setUpRange();
      this.setUpScales();
      this.setUpGenerators();
      this.createLayout();

      if (!this.hasData()) {
        this.showZeroState();
      } else {
        this.drawData();
        this.drawHoverBar();
        this.initHover();
      }
    },

    update: function (options) {
      this.settings.balanceByDate = options.balanceByDate;
      if (this.hasData()) {
        this.clearZeroState();
        this.setUpRange();
        this.updateScales();
        this.updateAxis();
        this.updateDrawnData("invested");
        this.updateDrawnData("balance");
        this.initHover();
      } else {
        this.showZeroState();
      }
    },

    showZeroState: function () {
      this.settings.$parentEl
        .find(".PerformanceBalanceGraph-zeroState")
        .addClass("u-displayFlex");
    },

    clearZeroState: function () {
      this.settings.$parentEl
        .find(".PerformanceBalanceGraph-zeroState")
        .removeClass("u-displayFlex");
    },

    waiting: function () {
      this.updateZeroData("invested");
      this.updateZeroData("balance");
    },

    setUpRange: function () {
      this.settings.maxBalance = d3.max(
        this.settings.balanceByDate,
        function (d) {
          return d.balance;
        }
      );
      this.settings.maxInvested = d3.max(
        this.settings.balanceByDate,
        function (d) {
          return d.totalInvested;
        }
      );
    },

    setUpScales: function () {
      this.scales = {
        x: d3.time
          .scale()
          .domain(
            d3.extent(this.settings.balanceByDate, function (d) {
              return d.date;
            })
          )
          .range([0, this.settings.width]),
        y: d3.scale
          .linear()
          .domain([
            0,
            this.settings.verticalBuffer *
              d3.max([this.settings.maxBalance, this.settings.maxInvested]),
          ])
          .range([this.settings.height, 0])
          .nice(),
      };
    },

    updateScales: function () {
      this.scales.x.domain(
        d3.extent(this.settings.balanceByDate, function (d) {
          return d.date;
        })
      );
      this.scales.y.domain([
        0,
        this.settings.verticalBuffer *
          d3.max([this.settings.maxBalance, this.settings.maxInvested]),
      ]);
    },

    setUpGenerators: function () {
      this.generators = {
        xAxis: d3.svg
          .axis()
          .scale(this.scales.x)
          .tickSize(10, 0)
          .tickValues(this.scales.x.domain())
          .tickPadding(6)
          .orient("bottom")
          .tickFormat(function (date) {
            return d3.time.format("%b %e, %Y")(date);
          }),
        yAxis: d3.svg
          .axis()
          .scale(this.scales.y)
          .tickSize(-1 * this.settings.width, 0)
          .ticks(this.settings.yTicks)
          .tickPadding(6)
          .tickFormat(function (d) {
            return d3.format("$,s")(d);
          })
          .orient("left"),
        line: {
          zero: d3.svg
            .line()
            .x(
              function (d) {
                return this.scales.x(d.date);
              }.bind(this)
            )
            .y(
              function (d) {
                return this.scales.y(0);
              }.bind(this)
            ),
          invested: d3.svg
            .line()
            .x(
              function (d) {
                return this.scales.x(d.date);
              }.bind(this)
            )
            .y(
              function (d) {
                return this.scales.y(d.totalInvested);
              }.bind(this)
            ),
          balance: d3.svg
            .line()
            .x(
              function (d) {
                return this.scales.x(d.date);
              }.bind(this)
            )
            .y(
              function (d) {
                return this.scales.y(d.balance);
              }.bind(this)
            ),
        },
        area: {
          zero: d3.svg
            .area()
            .x(
              function (d) {
                return this.scales.x(d.date);
              }.bind(this)
            )
            .y(
              function (d) {
                return this.scales.y(0);
              }.bind(this)
            ),
          balance: d3.svg
            .area()
            .x(
              function (d) {
                return this.scales.x(d.date);
              }.bind(this)
            )
            .y0(this.settings.height)
            .y1(
              function (d) {
                return this.scales.y(d.balance);
              }.bind(this)
            ),
        },
      };
    },
    createLayout: function () {
      this.svg = d3
        .select("#" + this.settings.$el.attr("id"))
        .attr(
          "width",
          this.settings.width +
            this.settings.margin.left +
            this.settings.margin.right
        )
        .attr(
          "height",
          this.settings.height +
            this.settings.margin.top +
            this.settings.margin.bottom
        )
        .on(
          "mousemove",
          function () {
            this.updateHover();
          }.bind(this)
        )
        .on(
          "mouseleave",
          function () {
            this.hideHover();
          }.bind(this)
        )
        .append("g")
        .attr(
          "transform",
          "translate(" +
            this.settings.margin.left +
            "," +
            this.settings.margin.top +
            ")"
        );

      this.svg
        .append("g")
        .attr(
          "class",
          "PerformanceBalanceGraph-axis PerformanceBalanceGraph-axis--x"
        )
        .attr("transform", "translate(0," + this.settings.height + ")")
        .call(this.generators.xAxis)
        .selectAll("text")
        .style("text-anchor", function (tick, idx) {
          return idx % 2 === 0 ? "start" : "end";
        });

      this.svg
        .append("g")
        .attr(
          "class",
          "PerformanceBalanceGraph-axis PerformanceBalanceGraph-axis--y"
        )
        .attr("transform", "translate(" + 0 + " , 0)")
        .call(this.generators.yAxis);

      this.svg
        .selectAll("g.tick")
        .attr(
          "class",
          "tick PerformanceBalanceGraph-axisTick PerformanceBalanceGraph-label"
        );

      this.svg
        .selectAll(".PerformanceBalanceGraph-axis--y g.tick line")
        .style("stroke-dasharray", function (line, idx) {
          return idx === 0 ? "none" : "5";
        });
    },

    updateAxis: function () {
      d3.select(".PerformanceBalanceGraph-axis.PerformanceBalanceGraph-axis--y")
        .transition()
        .ease(this.settings.transitionEasing)
        .duration(this.settings.transitionDuration)
        .call(this.generators.yAxis);

      d3.select(".PerformanceBalanceGraph-axis.PerformanceBalanceGraph-axis--x")
        .transition()
        .ease(this.settings.transitionEasing)
        .duration(this.settings.transitionDuration)
        .call(this.generators.xAxis)
        .selectAll("text")
        .style("text-anchor", function (tick, idx) {
          return idx % 2 === 0 ? "start" : "end";
        });

      this.svg
        .selectAll("g.tick")
        .attr(
          "class",
          "tick PerformanceBalanceGraph-axisTick PerformanceBalanceGraph-label"
        );
      this.svg
        .selectAll(".PerformanceBalanceGraph-axis--y g.tick line")
        .style("stroke-dasharray", function (line, idx) {
          return idx === 0 ? "none" : "5";
        });
    },

    drawData: function () {
      this.drawArea("balance");
      this.drawArea("invested");
      this.drawLine("balance");
      this.drawLine("invested");
      this.drawCircle("balance");
      this.drawCircle("invested");
      this.updateDrawnData("balance");
      this.updateDrawnData("invested");
    },

    updateDrawnData: function (type) {
      this.updateLine(type, type);
      this.updateArea(type, type);
    },

    updateZeroData: function (type) {
      this.updateLine(type, "zero");
      this.updateArea(type, "zero");
    },

    drawArea: function (type) {
      this.svg
        .append("path")
        .datum(this.settings.balanceByDate)
        .attr(
          "class",
          "PerformanceBalanceGraph-area PerformanceBalanceGraph-area--" + type
        )
        .attr("d", this.generators.area.zero);
    },

    updateArea: function (type, generator) {
      d3.select(
        ".PerformanceBalanceGraph-area.PerformanceBalanceGraph-area--" + type
      )
        .datum(this.settings.balanceByDate)
        .transition()
        .duration(this.settings.transitionDuration)
        .attr("d", this.generators.area[generator]);
    },

    drawLine: function (type) {
      this.svg
        .append("path")
        .datum(this.settings.balanceByDate)
        .attr(
          "class",
          "PerformanceBalanceGraph-line PerformanceBalanceGraph-line--" + type
        )
        .attr("d", this.generators.line.zero);
    },

    updateLine: function (type, generator) {
      d3.select(
        ".PerformanceBalanceGraph-line.PerformanceBalanceGraph-line--" + type
      )
        .datum(this.settings.balanceByDate)
        .transition()
        .duration(this.settings.transitionDuration)
        .attr("d", this.generators.line[generator]);
    },

    drawCircle: function (type) {
      this.svg
        .append("circle")
        .attr("r", 3)
        .attr(
          "class",
          "PerformanceBalanceGraph-hoverable PerformanceBalanceGraph-circle PerformanceBalanceGraph-circle--" +
            type
        );
    },

    drawHoverBar: function () {
      this.svg
        .append("path")
        .attr(
          "class",
          "PerformanceBalanceGraph-hoverable PerformanceBalanceGraph-bar"
        );
    },

    initHover: function () {
      var lastPoint = _.last(this.settings.balanceByDate);
      this.updateHoverBar(lastPoint.date);
      this.updateTooltip(lastPoint);
      this.updateCircles(lastPoint);
      this.hideHover();
    },

    updateHoverBar: function (date) {
      d3.select(
        "#" +
          this.settings.$el.attr("id") +
          " .PerformanceBalanceGraph-hoverable.PerformanceBalanceGraph-bar"
      )
        .classed("is-hovered", true)
        .attr(
          "d",
          "M" +
            this.scales.x(date) +
            "," +
            this.settings.height +
            "L" +
            this.scales.x(date) +
            "," +
            0
        );
    },

    updateTooltip: function (point) {
      var $tooltip = $(this.settings.tooltipClass),
        point = point,
        toolWidth = $tooltip.outerWidth(),
        offset = this.scales.x(point.date) - toolWidth,
        leftMargin = this.settings.margin.left;

      $tooltip
        .find(".PerformanceBalanceGraphTooltip-date")
        .text(dateFormat(point.date));
      $tooltip
        .find(".PerformanceBalanceGraphTooltip-segmentValue--balance")
        .text(currencyFormat(point.balance));
      $tooltip
        .find(".PerformanceBalanceGraphTooltip-segmentValue--invested")
        .text(currencyFormat(point.totalInvested));
      if (point.earned > 0) {
        $tooltip
          .find(".PerformanceBalanceGraphTooltip-segmentValue--earned")
          .text("+" + currencyFormat(point.earned))
          .addClass("u-color-bmtTeal90");
      } else {
        $tooltip
          .find(".PerformanceBalanceGraphTooltip-segmentValue--earned")
          .text(currencyFormat(point.earned))
          .removeClass("u-color-bmtTeal90");
      }
      $tooltip
        .css({
          left:
            this.scales.x(point.date) +
            leftMargin -
            (offset > 0 ? toolWidth - 1 : 1) +
            16,
        })
        .addClass("is-hovered");
    },

    updateCircles: function (point) {
      d3.selectAll(
        ".PerformanceBalanceGraph-hoverable.PerformanceBalanceGraph-circle"
      )
        .classed("is-hovered", true)
        .attr("cx", this.scales.x(point.date));
      d3.select(
        ".PerformanceBalanceGraph-circle.PerformanceBalanceGraph-circle--balance"
      ).attr("cy", this.scales.y(point.balance));
      d3.select(
        ".PerformanceBalanceGraph-circle.PerformanceBalanceGraph-circle--invested"
      ).attr("cy", this.scales.y(point.totalInvested));
    },

    updateHover: function (event) {
      var container = d3.select("#" + this.settings.$el.attr("id")),
        mouseX = d3.mouse(container.node())[0] - this.settings.margin.left;

      if (mouseX >= 0 && mouseX <= this.settings.width) {
        var exactDate = this.scales.x.invert(mouseX),
          point = this.getClosestDataPoint(exactDate);
        if (point) {
          this.updateHoverBar(point.date);
          this.updateTooltip(point);
          this.updateCircles(point);
        }
      } else {
        this.hideHover();
      }
    },

    getClosestDataPoint: function (date) {
      var bisect = d3.bisector(function (d) {
          return d.date;
        }).right,
        index = bisect(this.settings.balanceByDate, date),
        d0 = this.settings.balanceByDate[index - 1],
        d1 = this.settings.balanceByDate[index];

      if (d1 === undefined) {
        return d0;
      }
      if (d0 === undefined) {
        return d1;
      }

      return date - d0.date > d1.date - date ? d1 : d0;
    },

    hideHover: function () {
      d3.selectAll(".PerformanceBalanceGraph-hoverable").classed(
        "is-hovered",
        false
      );
    },
  });

  function dateFormat(date) {
    return d3.time.format("%B %d, %Y")(date);
  }

  function currencyFormat(amount) {
    return d3.format("$,.2f")(amount);
  }
})();
