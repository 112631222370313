import $ from 'jquery';

$.fn.showSpinner = function () {
  this.each(function () {
    var $this = $(this);

    if ($this.find('.spinner-bg, .svg-circle-spinner, .LoadingSpinner-background').length === 0) {
      var currentCssPosition = $this.css('position');

      if (currentCssPosition !== 'relative' && currentCssPosition !== 'absolute' && currentCssPosition !== 'fixed') {
        $this.css('position', 'relative');
      }

      var spinner = $('#loadingSpinnerTemplate').html();
      $this.append(spinner);
    }
  });

  return this;
};

$.fn.hideSpinner = function () {
  this.each(function () {
    $(this).find('.spinner-bg, .svg-circle-spinner, .LoadingSpinner-background').remove();
    $(this).css('position', 'auto');
  });

  return this;
};
