import $ from "jquery";

document.addEventListener("DOMContentLoaded", function () {
  (function () {
    $("form.edit_rollover_signup_flow").ready(function () {
      $(document).on("submit", ".simple_form", function (e) {
        $(e.target).startFormTransition();
      });
    });
  })();
});
