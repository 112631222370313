import $ from "jquery";

document.addEventListener("DOMContentLoaded", function () {
  (function () {
    $(".AppPerformanceReturns--show").ready(function () {
      $(".performance-returns form").on("change", function () {
        $("#returns_graph").returnsGraph("waiting");
      });

      $(".js-PerformanceReturns-form form").on("change", function () {
        $("#returns_graph").returnsGraph("waiting");
      });
    });
  })();
});
