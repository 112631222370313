import $ from "jquery";

(function() {
  $.fn.initPikaday = function() {
    this.each(function() {
      new Pikaday({
        field: this,
        format: $(this).data('format'),
        i18n: {
          months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
          weekdaysShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S']
        }
      });
    });
  };
}());
