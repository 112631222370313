/// <reference types="vite/client" />
import * as scLegacyInitializers from '@betterment/style-closet-legacy';
import { startCacheWorker } from '@betterment/js-runtime';
import { startStimulusApplication } from '@betterment/retail';
import jQuery from 'jquery';
import Turbolinks from 'turbolinks';
import '../vendor/jquery.turbolinks';
import Rails from '@rails/ujs';

import '../vendor/jquery.readyselector';
import '../vendor/jSignature';
import '../../../lib/assets/javascripts/jQuerySignaturePlugin';
import '../../../lib/assets/javascripts/jQuerySpinnerPlugin';
import '../vendor/qtip';
import '../vendor/webshim.range-input';
import '../vendor/lodash';
import '../vendor/modernizr';
import '../vendor/d3';
import '../vendor/typed-jquery';
import '../src/behaviors';
import '../src/form_behaviors';
import '../src/pages';
import '../src/turbolinks_workarounds';
import '../vendor/calendly-widget';
(window as any).$ = jQuery;
(window as any).jQuery = jQuery;
window.Turbolinks = Turbolinks;

declare global {
  interface Window {
    Turbolinks: typeof Turbolinks;
  }
}
Turbolinks.start();

import.meta.glob('../images', { eager: true });

for (const initializer in scLegacyInitializers) {
  scLegacyInitializers[initializer]();
}

if (!window._rails_loaded) Rails.start();

startStimulusApplication();
startCacheWorker();
