import $ from "jquery";

(function() {
  var TIME_UNTIL_POLLING_MILLISECONDS = 600000; // wait 10 minutes
  var POLL_INTERVAL_MILLISECONDS = 60000; // then check every minute to see if the session expired
  var sessionManager = new SessionManager();

  function SessionManager() {
  }

  SessionManager.prototype.enable = function() {
    this.startWaiting();
  };

  SessionManager.prototype.startWaiting = function() {
    this.stopWaiting();
    this.stopChecking();

    this.waitingTimer = setTimeout(function() {
      this.startChecking();
    }.bind(this), TIME_UNTIL_POLLING_MILLISECONDS);
  };

  SessionManager.prototype.stopWaiting = function() {
    clearTimeout(this.waitingTimer);
  };

  SessionManager.prototype.startChecking = function() {
    this.checkingTimer = setInterval(function() {
      this.check();
    }.bind(this), POLL_INTERVAL_MILLISECONDS);
  };

  SessionManager.prototype.stopChecking = function() {
    clearInterval(this.checkingTimer);
  };

  SessionManager.prototype.check = function() {
    $.get('/app/session');
  };

  SessionManager.prototype.refreshSession = function() {
    $.ajax({url: '/app/session', type: 'PUT'});
  };

  SessionManager.prototype.disable = function() {
    this.stopWaiting();
    this.stopChecking();
  };

  window.BMTSessionManager = window.BMTSessionManager || {
    enable: function() {
      sessionManager.enable();
    },
    disable: function() {
      sessionManager.disable();
    },
    refreshSession: function() {
      sessionManager.refreshSession();
    }
  };

  const bmtSessionEvent = new Event('bmtSession:loaded');
  window.dispatchEvent(bmtSessionEvent);
}());
