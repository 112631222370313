import $ from "jquery";

(function() {
  var displayToggle = function() {
    $('input#four_oh_one_k_onboarding_flow_has_preferred_first_name[type="checkbox"][data-display-toggle], input#employee_onboarding_flow_has_preferred_first_name[type="checkbox"][data-display-toggle]').on('change', function(e) {
      var $toggleElement = $(e.currentTarget);
      var $target = $($toggleElement.data('display-toggle-target'));
      var $toggleDisplayDirection = ($toggleElement.data('display-direction'));
      var $targetInput = $target.find('input').addBack('input')
      var isChecked = $toggleElement.is(':checked')

      $targetInput.prop('disabled', !isChecked);
      $target.toggle(isChecked);

    }).trigger('change');
  };

  $(document).on('turbolinks:load', displayToggle);
}());
