import $ from "jquery";

(function() {
  $(document).on('click', '.CheckboxListFilters a[data-behavior-select-all]', function(event) {
    event.preventDefault();

    var unchecked = $(event.target).parents('.CheckboxListFilters').find('input[type=checkbox]:not(:checked)');
    unchecked.prop('checked', true);
    $(unchecked[0]).trigger('change');
  });

  $(document).on('click', '.CheckboxListFilters a[data-behavior-select-only]', function(event) {
    event.preventDefault();

    $(event.target).parents('.CheckboxListFilters').find('input[type=checkbox]').prop('checked', false);
    $(event.target).parents('.checkbox').find('input[type=checkbox]').prop('checked', true).trigger('change');
  });
}());
